import Reveal from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import {
  fadeUp,
  HomeSection,
  HomeSectionInner,
  media,
  screen,
} from "../../../../pages/home/Home.style";
import Typography from "../../InnerTypography";
import { useTranslation } from "react-i18next";

const QuickSectionWrap = styled.div<{ language: string }>`
  > article {
    text-align: center;
    margin-bottom: 5.63rem;

    ${media.ta} {
      margin-bottom: 3.13rem;
    }
    ${media.mo} {
      margin-bottom: 2.5rem;
    }
  }
  > aside {
    width: auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 1.25rem;

    ${media.belowTa} {
      flex-flow: column nowrap;
      align-items: center;
      gap: 1rem;
    }
    ${media.mo} {
      padding: 0 20px;
    }
    .section {
      border-radius: 1rem;
      width: 36.8rem;
      height: 36.25rem;
      padding: 2.5rem;

      ${media.ta} {
        width: 42.875rem;
        height: 42.375rem;
      }
      ${media.mo} {
        width: 100%;
        height: 20.625rem;
      }

      &:first-of-type {
        background: #f7f8fa;
        background-image: ${({ language }) =>
          language === "ko"
            ? `url("/assets/images/quick_bg_1.png")`
            : `url("/assets/images/quick_${language}_bg_1.png")`};
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

      &:last-of-type {
        background: ${({ language }) =>
          (language === "ko"
            ? `url("/assets/images/quick_bg_2.png")`
            : `url("/assets/images/quick_${language}_bg_2.png")`) +
          `,
          linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
          linear-gradient(180deg, #003d99 0%, #5893ff 100%)`};
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }
  }
`;

const QuickSection = () => {
  const {
    i18n: { language },
  } = useTranslation("main");
  const isPc = useMediaQuery({ query: screen.pc });
  const isTa = useMediaQuery({ query: screen.ta });
  const isMo = useMediaQuery({ query: screen.mo });

  return (
    <HomeSection bg="black" pt={isPc ? 140 : isTa ? 120 : 150} pb={isMo ? 172 : 120}>
      <HomeSectionInner style={{ padding: 0 }}>
        <QuickSectionWrap language={language}>
          {!isMo ? (
            <article>
              <Reveal
                className="reveal"
                keyframes={fadeUp}
                duration={600}
                delay={100}
                cascade
                damping={0.25}
              >
                <Typography.Header3
                  fontWeight={700}
                  fontSize={"1.5rem"}
                  color="#5893FF"
                  letterSpacing={"-0.015rem"}
                >
                  {language === "ko" ? "Shorts" : "ショート動画"}
                </Typography.Header3>
                <Typography.Header2
                  fontWeight={700}
                  fontSize={"3.125rem"}
                  color="white"
                  letterSpacing={"-0.03125rem"}
                >
                  {language === "ko"
                    ? "등록부터 소비까지 손쉽게"
                    : "運営者も消費者もシームレスな経験"}
                </Typography.Header2>
              </Reveal>
            </article>
          ) : (
            <article>
              <Typography.Header3
                fontWeight={700}
                fontSize={"1.25rem"}
                color="#5893FF"
                letterSpacing={"-0.0125rem"}
              >
                {language === "ko" ? "Shorts" : "ショート動画"}
              </Typography.Header3>
              <Typography.Header2
                fontWeight={700}
                fontSize={"1.875rem"}
                color="white"
                letterSpacing={"-0.01875rem"}
              >
                {language === "ko" ? (
                  "등록부터 소비까지 손쉽게"
                ) : (
                  <>
                    運営者も消費者も
                    <br />
                    シームレスな経験
                  </>
                )}
              </Typography.Header2>
            </article>
          )}
          <aside>
            <div className="section">
              <Typography.Header4
                fontWeight={700}
                fontSize={isMo ? "1rem" : "1.5rem"}
                color="#003D99"
                lineHeight={"2.175rem"}
              >
                {language === "ko" ? "간편한 등록" : "シームレスな登録"}
              </Typography.Header4>
              <Typography.Header5
                fontWeight={400}
                fontSize={isMo ? "1rem" : "1.5rem"}
                color="rgba(34, 34, 34, 0.8)"
                lineHeight={"2.175rem"}
              >
                {language === "ko" ? "URL 붙여넣기로" : "URLのコピペで"}
              </Typography.Header5>
              <Typography.Header5
                fontWeight={400}
                fontSize={isMo ? "1rem" : "1.5rem"}
                color="rgba(34, 34, 34, 0.8)"
                lineHeight={"2.175rem"}
              >
                {language === "ko"
                  ? "간편하게 등록"
                  : isMo
                  ? "簡単読み込み"
                  : "SNSから簡単読み込み"}
              </Typography.Header5>
            </div>
            <div className="section">
              <Typography.Header4
                fontWeight={700}
                fontSize={isMo ? "1rem" : "1.5rem"}
                color="#FFF"
                lineHeight={"2.175rem"}
              >
                {language === "ko" ? "심플한 구매 경험" : "シームレスな購入経験"}
              </Typography.Header4>
              <Typography.Header5
                fontWeight={500}
                fontSize={isMo ? "1rem" : "1.5rem"}
                color="rgba(255, 255, 255, 0.7)"
                lineHeight={"2.175rem"}
              >
                {language === "ko" ? "마음에 드는 상품은" : "動画を視聴しながら"}
              </Typography.Header5>
              <Typography.Header5
                fontWeight={500}
                fontSize={isMo ? "1rem" : "1.5rem"}
                color="rgba(255, 255, 255, 0.7)"
                lineHeight={"2.175rem"}
              >
                {language === "ko" ? "바로 장바구니 담기" : "商品の発見から購入まで"}
              </Typography.Header5>
            </div>
          </aside>
        </QuickSectionWrap>
      </HomeSectionInner>
    </HomeSection>
  );
};

export default QuickSection;
